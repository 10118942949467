import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";

import { registerLocaleData } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import localePt from "@angular/common/locales/pt";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ngxLoadingAnimationTypes, NgxLoadingModule } from "ngx-loading";
import { LoadingService } from "src/app/shared/services/loading.service";
import { VexModule } from "../@vex/vex.module";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { CustomLayoutModule } from "./modules/custom-layout/custom-layout.module";
import { AuthInterceptor } from "./shared/interceptors/auth.interceptor";
import { OrmService } from "./shared/services/orm.service";
import { StorageService } from "./shared/services/storage.service";
import { UtilService } from "./shared/services/util.service";
import { ViacepService } from "./shared/services/viacep.service";

// Importação dos módulos de formulários
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatSnackBarModule } from "@angular/material/snack-bar";

registerLocaleData(localePt, "pt-BR");

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatSnackBarModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.circleSwish,
      backdropBackgroundColour: "rgba(0,0,0,0.5)",
      backdropBorderRadius: "4px",
      primaryColour: "#ffffff",
      secondaryColour: "#ffffff",
      tertiaryColour: "#ffffff",
      fullScreenBackdrop: true,
    }),
    // Vex
    VexModule,
    CustomLayoutModule,
    // Adição dos módulos de formulários
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: "pt-BR" },
    { provide: DEFAULT_CURRENCY_CODE, useValue: "BRL" },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    OrmService,
    ViacepService,
    LoadingService,
    UtilService,
    StorageService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
