import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgOtpInputModule } from 'ng-otp-input';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar'; 
import { SsoAuthService } from 'src/app/shared/services/sso-auth.service';
import { validarCpfCnpj } from 'src/app/shared/validator/cpfCnpj.validator';
import { Subscription, timer } from 'rxjs';
import { CountdownModule } from 'ngx-countdown';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';




@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.html',
  styleUrls: ['./forgot-password.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    NgOtpInputModule,
    CountdownModule

    // Remover NgOtpInputModule, CountdownModule e outras dependências
  ],
  
})
export class WForgotPasswordPage implements OnInit {
  group: FormGroup;
  newGroup: FormGroup;
  passwordChangeGroup: FormGroup;

  firstStep = true;
  secondStep = false;
  thirdStep = false;
  fourthStep = false;
  finalStep = false;
  isLoading = false;
  resendCode = false;
  loading = false;
  passwordsMatch = true;
  code = '';
  userEmail = '';
  userPhone = '';
  remainingTime = 300; // Tempo em segundos (5 minutos)
  remainingTimeSms = 120; // Tempo em segundos (2 minutos)
  timerSubscription: Subscription | null = null;

  configOtp = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    autofocus: true,
    placeholder: '',
    inputStyles: {
      width: '35px',
      height: '35px',
      border: 'none',
      background: '#EEE',
      color: "#8062F7",
      filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1))',
      outline: 'none',
    },
  };

  constructor(
    private fb: FormBuilder,
    private snackBar: MatSnackBar, 
    private router: Router,
    private ssoAuthService: SsoAuthService
  ) {
    this.group = this.fb.group({
      username: ['', [Validators.required, validarCpfCnpj()]],
    });

    this.newGroup = this.fb.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', [Validators.required]],
    });

    this.passwordChangeGroup = this.fb.group({
      newPassword: ['', [Validators.required, Validators.minLength(6)]],
      confirmNewPassword: ['', [Validators.required]],
    });
  }

  ngOnInit() {}

  async verifyUser() {
    this.isLoading = true;
    try {
      const username = this.removeSpecialCharacters(this.group.value.username);
      const response = await this.ssoAuthService.verifyCustomerExistenceTaxNumber(username);

      if (response?.data?.mail && response?.data?.phoneNumber) {
        this.userEmail = response.data.mail;
        this.userPhone = response.data.phoneNumber;
        this.firstStep = false;
        this.secondStep = true;
        await this.sendEmailOtp();
      } else {
        this.showWarning('Usuário não encontrado');
      }
    } catch (error) {
      console.error(error);
      this.showError('Erro ao buscar usuário');
    } finally {
      this.isLoading = false;
    }
  }

  maskEmail(email: string): string {
    const [localPart, domain] = email.split('@');
    const maskedLocalPart = localPart.slice(0, 3).padEnd(localPart.length, '*');
    return `${maskedLocalPart}@${domain}`;
  }

  async sendEmailOtp() {
    try {
      const res = await this.ssoAuthService.getEmailOtp(this.userEmail);

      if (res._id) {
        this.showSuccess('Código enviado para o email!');
        this.startTimer();
      } else {
        this.showError('Erro ao enviar o código');
      }
    } catch (error) {
      console.log(error);
      this.showError('Erro ao enviar o código');
    }
  }

  onTick(event: any) {
    this.remainingTime = event.value; // Atualiza o tempo restante
  }
  
  onTimerComplete() {
    this.resendCode = true; // Habilita o botão de reenviar código
  }
  

  async sendPhoneOtp() {
    try {
      const res = await this.ssoAuthService.getPhoneOtp(this.userPhone);

      if (res.success) {
        this.fourthStep = true;
        this.thirdStep = false;
        this.showSuccess('Código enviado para o telefone!');
      } else {
        this.showError('Erro ao enviar o código');
      }
    } catch (error) {
      console.log(error);
      this.showError('Erro ao enviar o código');
    }
  }

  onOtpChange(event: any) {
    this.code = event;
  }

  async validateEmailOtp() {
    this.resendCode = false;
    this.loading = true;

    try {
      const res = await this.ssoAuthService.validateEmailOtp(this.userEmail, this.code);

      if (res.data?.isValid) {
        this.showSuccess('Código validado com sucesso!');
        this.secondStep = false;
        this.thirdStep = true;
      } else {
        this.showWarning('Código inválido!');
      }
    } catch (error) {
      this.showError('Ocorreu um erro, tente novamente.');
      console.log(error);
    } finally {
      this.loading = false;
    }
  }

  async sendSmsOtp() {
    try {
      const res = await this.ssoAuthService.forgotPassword({
        username: this.removeSpecialCharacters(this.group.value.username),
        phone: this.userPhone,
      });
      if (res.success) {
        this.thirdStep = false;
        this.fourthStep = true;
        this.showSuccess('Código enviado para o telefone!');
      } else {
        this.showError('Erro ao enviar o código');
      }
    } catch (error) {
      this.showError('Erro ao enviar o código');
      console.log(error);
    }
  }

  formatPhoneNumber(phone: string): string {
    const cleaned = phone.replace(/\D/g, '');
    let formattedPhone = '';

    if (cleaned.length === 10) {
      formattedPhone = `(${cleaned.slice(0, 2)}) ${cleaned.slice(2, 6)}-****`;
    } else if (cleaned.length === 11) {
      formattedPhone = `(${cleaned.slice(0, 2)}) ${cleaned.slice(2, 7)}-****`;
    } else {
      formattedPhone = phone;
    }

    return formattedPhone;
  }

  confirmPassword() {
    if (this.newGroup.value.password === this.newGroup.value.confirmPassword) {
      this.fourthStep = false;
      this.finalStep = true;
      this.showSuccess('Senha alterada com sucesso!');
    } else {
      this.showError('As senhas não coincidem');
    }
  }

  returnToLogin() {
    this.finalStep = false;
    this.firstStep = true;
    this.router.navigate(['/']);
  }

  removeSpecialCharacters(value: string): string {
    return value.replace(/[^\d]/g, '');
  }

  async finalizeReset() {
    this.loading = true;
    try {
      const response = await this.ssoAuthService.resetPassword({
        newPassword: this.newGroup.value.password,
        code: this.code,
        username: this.removeSpecialCharacters(this.group.value.username),
      });

      if (response.success) {
        this.showSuccess('Senha redefinida com sucesso!');
        this.finalStep = true;
        this.fourthStep = false;
      } else {
        this.showError('Erro ao redefinir a senha');
      }
    } catch (error) {
      this.showError('Erro ao redefinir a senha');
      console.error(error);
    } finally {
      this.loading = false;
    }
  }

  formatCpfCnpj(value: string): string {
    if (!value) return '';

    const cleanedValue = this.removeSpecialCharacters(value);

    if (cleanedValue.length <= 11) {
      return cleanedValue.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    } else {
      return cleanedValue.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    }
  }

  private showSuccess(message: string) {
    this.snackBar.open(message, 'Fechar', {
      duration: 3000,
      panelClass: ['snackbar-success'],
    });
  }

  private showError(message: string) {
    this.snackBar.open(message, 'Fechar', {
      duration: 3000,
      panelClass: ['snackbar-error'],
    });
  }

  private showWarning(message: string) {
    this.snackBar.open(message, 'Fechar', {
      duration: 3000,
      panelClass: ['snackbar-warning'],
    });
  }

  private startTimer() {
    this.remainingTime = 300; // Tempo em segundos (5 minutos)
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
    this.timerSubscription = timer(0, 1000).subscribe(() => {
      this.remainingTime--;
      if (this.remainingTime <= 0) {
        this.resendCode = true;
        if (this.timerSubscription) {
          this.timerSubscription.unsubscribe();
        }
      }
    });
  }

  private startSmsTimer() {
    this.remainingTimeSms = 120; // Tempo em segundos (2 minutos)
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
    this.timerSubscription = timer(0, 1000).subscribe(() => {
      this.remainingTimeSms--;
      if (this.remainingTimeSms <= 0) {
        this.resendCode = true;
        if (this.timerSubscription) {
          this.timerSubscription.unsubscribe();
        }
      }
    });
  }

  async timer(ev: any) {
    if (ev.action === 'done') {
      this.resendCode = true;
    }
  }

  onInputChange(event: any) {
    let input = event.target.value;

    // Remove caracteres especiais e limita a 14 dígitos
    const cleanedValue = this.removeSpecialCharacters(input).slice(0, 14);

    // Aplica a máscara de CPF ou CNPJ
    const formattedValue = this.formatCpfCnpj(cleanedValue);

    // Atualiza o valor do controle de formulário
    this.group.get('username')?.setValue(formattedValue, { emitEvent: false });
  }
}
