import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class UtilService {
  constructor() {}

  async converterFileToBase64(file): Promise<any> {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    return new Promise((resolve, reject) => {
      reader.onloadend = () => {
        resolve(reader.result as string);
      };
    });
  }

  async pegarTipoDocumento(tipo, posicao) {
    if (posicao === "frente") {
      switch (tipo) {
        case "rg":
          return 0;
        case "cpf":
          return 1;
        case "cnh":
          return 3;
        case "Cartão CNPJ":
          return 4;
        case "Contrato Social":
          return 5;
        case "Procuração":
          return 6;
        case "Ato Constitutivo":
          return 11;
        case "Outro Documento":
          return 13;
        case "Faturamento Anual":
          return 17;
      }
    } else {
      switch (tipo) {
        case "rg":
          return 7;
        case "cnh":
          return 16;
      }
    }
  }

  async pegarFormatoArquivo(tipo: string) {
    switch (tipo.split("/")[1]) {
      case "pdf":
        return 0;
      case "jpg":
        return 1;
      case "jpeg":
        return 2;
      case "txt":
        return 3;
      case "png":
        return 4;
      default:
        return "";
    }
  }

  getTranslatedDocumentName(documentName: string) {
    switch (documentName) {
      case "documentFront":
        return "Documento Frente";
      case "documentBack":
        return "Documento Verso";
      case "documentCPF":
        return "Documento CPF";
      case "documentResidence":
        return "Comprovante de Residência";
      case "documentRuralProducer":
        return "Carteira de Produtor Rural";
      case "documentWeddingCertificate":
        return "Certidão de Casamento";
      case "documentProveIncome":
        return "Comprovante de Rendimentos";
      case "paycheckLastThreeMonths":
        return "Contracheque Últimos Três Meses";
      case "paycheckLastTwoMonths":
        return "Contracheque Últimos Dois Meses";
      case "paycheckLastMonth":
        return "Contracheque Último Mês";
      default:
        return "";
    }
  }

  async documentName(name: string) {
    switch (name) {
      case "cnpjCard":
        return "Cartão CNPJ";
      case "declaration":
        return "Contrato Social, ata de constituição, estatuto ou declaração";
      case "businessLicense":
        return "Alvará de funcionamento";
      case "balanceSheet":
        return "Balanço patrimonial";
      case "result":
        return "Demonstração do resultado do exercício";
      case "openingBalance":
        return "Balanço de Abertura";
      case "salesForecast":
        return "Previsão de Faturamento nos primeiros 12 meses";
      case "proofResidence":
        return "Comprovante de residencia";
      case "balanceSPED":
        return "Balanço emitido pelo SPED";
      case "bookkeeping":
        return "Escrituração Contábil";
      case "rgFront":
        return "rg frente";
      case "rgBack":
        return "rg verso";
      case "cpf":
        return "cpf";
      case "residencePartner":
        return "Comprovante de residencia do sócio";
      case "irpf":
        return "Comprovante de renda pessoa fisica do ultimo exercicio";
    }
  }

  getDocumentType(type: string, position?: "frente" | "verso") {
    switch (type) {
      case "cpf":
        return 1;
      case "rg":
        if (position === "frente") {
          return 0;
        } else {
          return 7;
        }
      case "cnh":
        if (position === "frente") {
          return 3;
        } else {
          return 16;
        }
      case "Comprovante de Residência":
        return 2;
      case "Cartão CNPJ":
        return 4;
      case "Contrato Social":
        return 5;
      case "Procuração":
        return 6;
      case "Ato Constitutivo":
        return 11;
      case "Outro Documento":
        return 13;
      case "Faturamento Anual":
        return 17;
      case "Carteira de Produtor Rural":
        return 18;
      case "Certidão de Casamento":
        return 19;
      case "Comprovante de Rendimentos":
        return 20;
      case "Contracheque Últimos Três Meses":
        return 21;
      case "Contracheque Últimos Dois Meses":
        return 22;
      case "Contracheque Último Mês":
        return 23;
      case "Cartão CNPJ":
        return 24;
      case "Contrato Social, ata de constituição, estatuto ou declaração":
        return 25;
      case "Alvará de funcionamento":
        return 26;
      case "Balanço patrimonial":
        return 27;
      case "Demonstração do resultado do exercício":
        return 28;
      case "Previsão de Faturamento nos primeiros 12 meses":
        return 29;
      case "Comprovante de residencia":
        return 30;
      case "Balanço emitido pelo SPED":
        return 31;
      case "Escrituração Contábil":
        return 32;
      case "Balanço de Abertura":
        return 33;
      case "Certificado de Registro de Veículo - CRV":
        return 34;
      case "Escritura bem imóvel":
        return 35;
      case "Comprovante de residencia do sócio":
        return 36;
      case "Comprovante de renda pessoa fisica do ultimo exercicio":
        return 37;
      case "Comprovante de Renda":
        return 24;
    }
  }

  formatTimerInMinutes(n: number): string {
    const minutes: number = Math.trunc(n / 60);
    const seconds: number = n % 60;

    const formatedMinutes: string =
      minutes >= 10 ? String(minutes) : `0${minutes}`;
    const formatedSeconds: string =
      seconds >= 10 ? String(seconds) : `0${seconds}`;

    return `${formatedMinutes}:${formatedSeconds}`;
  }
}
