<!-- Primeira Etapa -->
<ng-container *ngIf="firstStep">
  <div class="form-container">
    <img src="../../../assets/img/logo/logo2.png" alt="Logo" class="logo">
    <form [formGroup]="group" (ngSubmit)="verifyUser()">
      <div class="form-group">
        <h2 for="username">CPF ou CNPJ:</h2>
        <input
          id="username"
          formControlName="username"
          type="text"
          placeholder="CPF ou CNPJ"
          (input)="onInputChange($event)"
          class="input"
        />
        <div
          *ngIf="group.get('username')?.invalid && group.get('username')?.touched"
          class="error-message"
        >
          <small *ngIf="group.get('username')?.errors?.['required']">Campo obrigatório</small>
          <small *ngIf="group.get('username')?.errors?.['invalidCpfCnpj']">CPF ou CNPJ inválido</small>
        </div>
      </div>
      <div class="w-full mt-6">
        <button
          [disabled]="group.invalid"
          class="btn"
          mat-raised-button
          type="button"
        >
          Verificar
        </button>
      </div>
    </form>
  </div>
</ng-container>

<!-- Segunda Etapa -->
<ng-container *ngIf="secondStep">
  <div class="form-container-2">
    <img src="../../../assets/img/logo/logo2.png" alt="Logo" class="logo">
    <div class="text-center" style="margin-top: 20px">
      <h3 class="title">Confirmação de E-mail</h3>
      <p>Um código foi enviado para o e-mail: {{ maskEmail(userEmail) }}</p>
    </div>
    <form (ngSubmit)="validateEmailOtp()" class="otp-form">
      <div class="otp-container">
        <ng-otp-input
            [config]="configOtp"
            (onInputChange)="onOtpChange($event)"
            class="otp-input"
          ></ng-otp-input>
      </div>
      <countdown
          #cd
          [config]="{ leftTime: 60, format: 'mm:ss' }"
          style="margin-top: 1rem; color: #aaa"
          (event)="timer($event)"
        ></countdown>
      


      <button
        [disabled]="!resendCode"
        class="btn"
        (click)="sendEmailOtp()"
      >
        Reenviar Código
      </button>
      <button
        class="btn"
        [disabled]="code.length !== 6"
        type="submit"
      >
        Avançar
      </button>
    </form>
  </div>
</ng-container>

<!-- Terceira Etapa -->
<ng-container *ngIf="thirdStep">
  <div class="form-container">
    <img src="../../../assets/img/logo/logo2.png" alt="Logo" class="logo">
    <form [formGroup]="newGroup" (ngSubmit)="sendSmsOtp()">
      <div class="form-group">
        <h2 for="password">Nova Senha:</h2>
        <input
          id="password"
          formControlName="password"
          type="password"
          placeholder="Digite sua nova senha"
          class="input"
        />
        <div
          *ngIf="newGroup.get('password')?.invalid && newGroup.get('password')?.touched"
          class="error-message"
        >
          <small *ngIf="newGroup.get('password')?.errors?.['required']">Campo obrigatório</small>
          <small *ngIf="newGroup.get('password')?.errors?.['minlength']">A senha deve ter no mínimo 6 caracteres</small>
        </div>
      </div>
      <div class="form-group">
        <h2 for="confirmPassword">Confirmar Senha:</h2>
        <input
          id="confirmPassword"
          formControlName="confirmPassword"
          type="password"
          placeholder="Confirme sua nova senha"
          class="input"
        />
        <div
          *ngIf="newGroup.get('confirmPassword')?.invalid && newGroup.get('confirmPassword')?.touched"
          class="error-message"
        >
          <small *ngIf="newGroup.get('confirmPassword')?.errors?.['required']">Campo obrigatório</small>
        </div>
      </div>
      <button
        class="btn"
        type="submit"
        [disabled]="loading"
      >
        Avançar
      </button>
    </form>
  </div>
</ng-container>

<!-- Quarta Etapa -->
<ng-container *ngIf="fourthStep">
  <div class="form-container-2">
    <img src="../../../assets/img/logo/logo2.png" alt="Logo" class="logo">
    <div class="text-center" style="margin-top: 20px">
      <h3 class="title">Confirmação de Número de Celular</h3>
      <p>Um código foi enviado para o número: {{ formatPhoneNumber(userPhone) }}</p>
    </div>
    <form (ngSubmit)="finalizeReset()" class="otp-form">
      <div class="otp-container">
        <ng-otp-input
            [config]="configOtp"
            (onInputChange)="onOtpChange($event)"
            class="otp-input"
          ></ng-otp-input>
      </div>
      <countdown
          #cd
          [config]="{ leftTime: 60, format: 'mm:ss' }"
          style="margin-top: 1rem; color: #aaa"
          (event)="timer($event)"
        ></countdown>
      
      <button
        [disabled]="!resendCode"
        class="btn"
        (click)="sendSmsOtp()"
      >
        Reenviar Código
      </button>
      <button
        class="btn"
        [disabled]="code.length !== 6"
        type="submit"
      >
        Redefinir Senha
      </button>
    </form>
  </div>
</ng-container>

<!-- Etapa Final -->
<ng-container *ngIf="finalStep">
  <div class="form-container">
    <img src="../../../assets/img/logo/logo2.png" alt="Logo" class="logo">
    <p class="label">Senha alterada com sucesso!</p>
    <div class="w-full mt-6">
      <button
        class="btn"
        (click)="returnToLogin()"
      >
        Voltar para o login
      </button>
    </div>
  </div>
</ng-container>
