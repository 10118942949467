import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function validarCpfCnpj(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {

    const cpfCnpj = control.value;
    const cpf_cnpj =  cpfCnpj.replace(/[. -/]/g, '');
 
    if (cpf_cnpj) {
      if (cpf_cnpj.length <= 11) {
        //CPF

        let numbers;
        let digits;
        let sum;
        let i;
        let result;
        let equalDigits;
        equalDigits = 1;

        if (cpf_cnpj.length < 11) {
          return { cpfNotValid: true };
        }

        for (i = 0; i < cpf_cnpj.length - 1; i++) {
          if (cpf_cnpj.charAt(i) !== cpf_cnpj.charAt(i + 1)) {
            equalDigits = 0;
            break;
          }
        }

        if (!equalDigits) {
          numbers = cpf_cnpj.substring(0, 9);
          digits = cpf_cnpj.substring(9);
          sum = 0;
          for (i = 10; i > 1; i--) {
            sum += numbers.charAt(10 - i) * i;
          }

          result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

          if (result !== Number(digits.charAt(0))) {
            return { cpfNotValid: true };
          }
          numbers = cpf_cnpj.substring(0, 10);
          sum = 0;

          for (i = 11; i > 1; i--) {
            sum += numbers.charAt(11 - i) * i;
          }
          result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

          if (result !== Number(digits.charAt(1))) {
            return { cpfNotValid: true };
          }
          return null;
        } else {
          return { cpfNotValid: true };
        }
      } else {
        //CNPJ

        if (cpf_cnpj.length != 14) {
          return { cnpjNotValid: true };
        }
        // Validação do primeiro dígito verificador
        let sum = 0;
        let weight = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
        for (let i = 0; i < 12; i++) {
          sum += parseInt(cpf_cnpj[i]) * weight[i];
        }
        let mod = sum % 11;
        let dig1 = mod < 2 ? 0 : 11 - mod;

        // Validação do segundo dígito verificador
        sum = 0;
        weight = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
        for (let i = 0; i < 13; i++) {
          sum += parseInt(cpf_cnpj[i]) * weight[i];
        }
        mod = sum % 11;
        let dig2 = mod < 2 ? 0 : 11 - mod;

        // Verifica se os dígitos verificadores estão corretos
        if (parseInt(cpf_cnpj[12]) !== dig1 || parseInt(cpf_cnpj[13]) !== dig2) {
          return { cnpjNotValid: true };
        }
      }
    }

    return null;
  };
}
